#meeting-zoom {
    top: 180px !important;
    left: 0 !important;
}

#meeting-zoom .MuiToolbar-root,
#meeting-zoom #suspension-view-tabpanel-speaker > div > div.jss35.in-sharing > div.MuiBox-root.jss127.jss122 {
    display: none !important;
}

#meeting-zoom .react-draggable {
    width: calc(100vw - 20px) !important;
    min-height: 550px !important;
    margin-left: 5px;
}

#meeting-zoom .MuiPaper-root {
    width: 100% !important;
}