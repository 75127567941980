body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f2f6fd;
}

body,
html,
#root {
    position: absolute;
    height: 100%;
    width: 100%;
    min-width: 280px;
    margin: 0;
    padding: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.bold {
    font-weight: bold;
}

/* Blinks */
.blinks {
    animation: blinks 1s linear infinite alternate;
}

@keyframes blinks {
    0% {
        opacity: 0.2;
    }

    100% {
        opacity: 0.8;
    }
}

/* appBar */
.MuiToolbar-regular {
    min-height: 64px !important;
}

@media (max-width: 767px) {
    .MuiToolbar-regular {
        min-height: 56px !important;
    }
}

/* Sweet Alert */
.sweet-alert .sa-icon {
    width: 60px !important;
    height: 60px !important;
}

.sweet-alert .sa-icon.sa-warning .sa-body {
    height: 28px !important;
}

.sweet-alert h2 {
    font-size: 25px !important;
    margin: 10px 0 !important;
    font-weight: bold !important;
    line-height: 27px !important;
}

.sweet-alert button {
    font-size: 14px !important;
    margin: 10px 5px 0 5px !important;
}

.sweet-alert button.cancel {
    background-color: #5E6E82 !important;
}

.sweet-alert button {
    background-color: #3F51BF !important;
    text-transform: uppercase !important;
    font-size: 12px !important;
}